export const API_RESOURCES = {
  ASSEMBLY_META: 'assembly-meta',
  ASSEMBLY_PART_META: 'assembly-part-meta',
  DESIGN_FILE: 'design-file',
  DOCUMENT: 'document',
  PRINTER: 'printer',
  POST_PROCESSOR: 'post-processor',
  MATERIAL: 'material',
  MATERIAL_CONTAINER: 'material-container',
  MATERIAL_BATCH: 'material-batch',
  MATERIAL_LOT: 'material-lot',
  MATERIAL_BATCH_ACTION: 'material-batch-action',
  RUN: 'run',
  RUN_ACTUALS: 'run-actuals',
  PRINT: 'print',
  PRODUCT: 'product',
  PIECE: 'piece',
  LINE_ITEM: 'line-item',
  MODEL: 'model',
  ORDER: 'order',
  SCHEDULE_RUNS: 'schedule_runs',
  TIME_ENTRY: 'time-entry',
  WORK_INSTRUCTION_REPORT: 'work-instruction-report',
  WORK_CHECKLIST_FOR_RESOURCE: 'work-checklists-for-resource',
  WORKFLOW: 'workflow',
  PUBLIC_GROUP: 'public-group',
  SHIPMENT: 'shipment',
  // Permissions system check data
  // (based on Public Group, but with extended bureau permissions check, see BE implementation for more details)
  ACCESS_INFO_FOR_RESOURCE: 'access-info-for-resource',
  RUN_TRANSFORMATION: 'run-transformation',
  PROCESS_STEP: 'process-step',
  COMMENT: 'comment',
  USERS: 'users',
  SAMPLE: 'sample',
  LOCATION: 'location',
  SUB_LOCATION: 'sub-location',
};

export const API_RESOURCES_MAP = {
  [API_RESOURCES.ASSEMBLY_META]: 'assembly meta',
  [API_RESOURCES.ASSEMBLY_PART_META]: 'assembly part meta',
  [API_RESOURCES.ACCESS_INFO_FOR_RESOURCE]: 'access info for resource',
  [API_RESOURCES.DESIGN_FILE]: 'design file',
  [API_RESOURCES.DOCUMENT]: 'document',
  [API_RESOURCES.PRINTER]: 'machine',
  [API_RESOURCES.POST_PROCESSOR]: 'machine',
  [API_RESOURCES.MATERIAL]: 'material',
  [API_RESOURCES.MATERIAL_CONTAINER]: 'container',
  [API_RESOURCES.MATERIAL_BATCH]: 'material batch',
  [API_RESOURCES.MATERIAL_LOT]: 'material lot',
  [API_RESOURCES.MATERIAL_BATCH_ACTION]: 'material batch action',
  [API_RESOURCES.RUN]: 'run',
  [API_RESOURCES.PRODUCT]: 'product',
  [API_RESOURCES.RUN_ACTUALS]: 'run actuals',
  [API_RESOURCES.PRINT]: 'print',
  [API_RESOURCES.PIECE]: 'piece',
  [API_RESOURCES.LINE_ITEM]: 'line item',
  [API_RESOURCES.MODEL]: 'model',
  [API_RESOURCES.ORDER]: 'order',
  [API_RESOURCES.PUBLIC_GROUP]: 'public group',
  [API_RESOURCES.SCHEDULE_RUNS]: 'scheduled runs',
  [API_RESOURCES.TIME_ENTRY]: 'time entry',
  [API_RESOURCES.WORK_INSTRUCTION_REPORT]: 'work instruction report',
  [API_RESOURCES.WORK_CHECKLIST_FOR_RESOURCE]: 'work checklists for resource',
  [API_RESOURCES.WORKFLOW]: 'workflow',
  [API_RESOURCES.SHIPMENT]: 'shipment',
  [API_RESOURCES.PROCESS_STEP]: 'process step',
  [API_RESOURCES.COMMENT]: 'comments',
  [API_RESOURCES.USERS]: 'users',
  [API_RESOURCES.RUN_TRANSFORMATION]: 'run-transformation',
  [API_RESOURCES.SAMPLE]: 'sample',
  [API_RESOURCES.SUB_LOCATION]: 'sub-location',
  [API_RESOURCES.LOCATION]: 'location',
};

export const ACCESS_INFO_ACTION_TYPES = {
  EDIT: 'edit', // PUT request
  DELETE: 'delete', // DELETE request
  USE: 'use', // using an entity URI in other entity field. E.g. `process_step.workstation_type`, etc.
  // Edge-case actions
  REDO: 'redo', // `redo` action is a Run transformation on some/all of Run.Prints provided for a Run resource
  REMANUFACTURE: 'remanufacture', // `remanufacture` is a Run transformation action, provided on a piece resource
  CHANGE_WORKFLOW: 'change_workflow', // `change workflow` is a Run transformation action, provided on a piece resource
};

export const LINE_ITEM_COMPOSITION_TYPES = {
  SINGLE_MESH_PRODUCT: 'single-mesh-product', // Regular 1 model product line item
  CO_PRINT: 'co-print', // co-printed multi-mesh line item (main co-print assembly line item)
  CO_PRINT_PART: 'co-print-part', // co-printed multi-mesh line item part
  ASSEMBLY: 'assembly', // main glue/magnet assembly line item
  ASSEMBLY_PART: 'assembly-part', // glue/magnet assembly part line item
};

export const RUN_STATUS = {
  created: 'created',
  calculating: 'calculating',
  calculated: 'calculated',
  queued: 'queued',
  inProgress: 'in-progress',
  paused: 'paused',
  complete: 'complete',
  error: 'error',
  cancelled: 'cancelled',
};

// In backend implementation it's like the same statuses, as in run,
// but also with `created` and `incomplete` statuses
export const PRINT_STATUSES = Object.assign({}, RUN_STATUS, {
  CREATED: 'created',
  INCOMPLETE: 'incomplete',
});

export const PRINT_ERROR_STATUSES = [
    PRINT_STATUSES.error,
    PRINT_STATUSES.cancelled,
    PRINT_STATUSES.INCOMPLETE,
];

export const RUN_STATUS_ORDER = [
  RUN_STATUS.created,
  RUN_STATUS.calculating,
  RUN_STATUS.calculated,
  RUN_STATUS.queued,
  RUN_STATUS.inProgress,
  RUN_STATUS.paused,
  RUN_STATUS.complete,
  RUN_STATUS.error,
  RUN_STATUS.cancelled,
];

export const RUN_STATUS_LABELS = {
  [RUN_STATUS.created]: 'Created',
  [RUN_STATUS.calculating]: 'Calculating',
  [RUN_STATUS.calculated]: 'Calculated',
  [RUN_STATUS.queued]: 'Queued',
  [RUN_STATUS.inProgress]: 'In Progress',
  [RUN_STATUS.paused]: 'Paused',
  [RUN_STATUS.complete]: 'Completed',
  [RUN_STATUS.error]: 'Failed',
  [RUN_STATUS.cancelled]: 'Cancelled',
};

export const RUN_OPERATIONS = {
  PRINTING: 'printing',
  POST_PROCESSING: 'post-processing',
  SHIPPING: 'shipping',
};

export const NCR_FLAG = "flag_non_conformance";

export const PIECE_STATUS = {
  NEW: 'new',
  CONFIRMED: 'confirmed',
  QUEUED_FOR_PRINTING: 'queued-for-printing',
  PRINTING: 'printing',
  QUEUED_FOR_POST_PROCESSING: 'queued-for-post-processing',
  POST_PROCESSING: 'post-processing',
  QUEUED_FOR_SHIPPING: 'queued-for-shipping',
  SHIPPING: 'shipping',
  COMPLETE: 'complete',
  INCOMPLETE: 'incomplete',
  CANCELLED: 'cancelled',
  ERROR: 'error',
};

export const PIECE_STATUS_BADGE_COLORS = {
  [PIECE_STATUS.NEW]: '#a799ff',
  [PIECE_STATUS.CONFIRMED]: '#04a5e2',
  [PIECE_STATUS.QUEUED_FOR_PRINTING]: '#04a5e2',
  [PIECE_STATUS.PRINTING]: '#ffac00',
  [PIECE_STATUS.QUEUED_FOR_POST_PROCESSING]: '#04a5e2',
  [PIECE_STATUS.POST_PROCESSING]: '#ffac00',
  [PIECE_STATUS.QUEUED_FOR_SHIPPING]: '#04a5e2',
  [PIECE_STATUS.SHIPPING]: '#5f5f61',
  [PIECE_STATUS.COMPLETE]: '#0abb99',
  [PIECE_STATUS.INCOMPLETE]: '#5f5f61',
  [PIECE_STATUS.CANCELLED]: '#5f5f61',
  [PIECE_STATUS.ERROR]: '#f26264',
};

export const PIECE_TYPES = {
  PRODUCT: 'product',
  SPECIMEN: 'specimen',
  // Legacy, not used anywhere
  WASTE: 'waste',
  TOOLING: 'tooling',
};

export const WORK_INSTRUCTION_TYPES = {
  TEXT: 'text',
  NUMBER: 'number',
  CHECKBOX: 'checkbox',
  NO_ENTRY: 'no entry',
  RANGE: 'range',
  DOCUMENT: 'document',
  PICTURE: 'picture',
  SINGLE_SELECT_DROPDOWN: 'single-selection-dropdown',
};

export const WORK_INSTRUCTION_DOCUMENT_TYPES = [
  WORK_INSTRUCTION_TYPES.DOCUMENT,
  WORK_INSTRUCTION_TYPES.PICTURE,
]

export const MATERIAL_BATCH_ACTIONS = {
  CREATE_INITIAL_BATCH: 'create_initial_batch',
  MACHINE_LOAD: 'machine_load',
  BLEND_BATCHES: 'blend_batches',
  MACHINE_UNLOAD_CHAMBER: 'machine_unload_chamber',
  UNLOAD_RECLAIMED_MATERIAL: 'unload_reclaimed_material',
  SPLIT_BATCH: 'split_batch',
  ADD_CONTAINER: 'add_container',
  MACHINE_UNLOAD_HOPPER: 'machine_unload_hopper',
  SCRAP_BATCH: 'scrap_batch',
  SIEVE: 'sieve',
  TEST: 'test',
  MACHINE_TOP_OFF: 'machine_top_off',
  RELOCATE: 'relocate',
  TREATMENT: 'treatment',
};

export const MATERIAL_BATCH_ACTIONS_VERBOSE = {
  [MATERIAL_BATCH_ACTIONS.CREATE_INITIAL_BATCH]: 'Initial Batch Process',
  [MATERIAL_BATCH_ACTIONS.MACHINE_LOAD]: 'Machine Load',
  [MATERIAL_BATCH_ACTIONS.BLEND_BATCHES]: 'Blend Batches',
  [MATERIAL_BATCH_ACTIONS.MACHINE_UNLOAD_CHAMBER]: 'Machine Unload Chamber',
  [MATERIAL_BATCH_ACTIONS.SPLIT_BATCH]: 'Split Batch',
  [MATERIAL_BATCH_ACTIONS.ADD_CONTAINER]: 'Add Container',
  [MATERIAL_BATCH_ACTIONS.MACHINE_UNLOAD_HOPPER]: 'Machine Unload Hopper',
  [MATERIAL_BATCH_ACTIONS.SCRAP_BATCH]: 'Scrap Batch',
  [MATERIAL_BATCH_ACTIONS.SIEVE]: 'Sieve',
  [MATERIAL_BATCH_ACTIONS.RELOCATE]: 'Relocate',
  [MATERIAL_BATCH_ACTIONS.TREATMENT]: 'Treatment',
  [MATERIAL_BATCH_ACTIONS.TEST]: 'Test',
  [MATERIAL_BATCH_ACTIONS.MACHINE_TOP_OFF]: 'Top Off',
  [MATERIAL_BATCH_ACTIONS.UNLOAD_RECLAIMED_MATERIAL]: 'Unload Reclaimed Material',
};

export const MATERIAL_BATCH_STATUSES = {
  NEW: 'new',
  IN_USE: 'in_use',
  DONE: 'done',
};

export const MATERIAL_CONTAINER_STATUSES = {
  NEW: 'new',
  IN_USE: 'in_use',
  EMPTY: 'empty',
};

export const MATERIAL_BATCH_CUSTOM_STATUSES = {
  IN_TESTING: 'in_testing',
  TESTING_COMPLETE: 'testing_complete',
};

export const MATERIAL_BATCH_STATUS_VERBOSE = {
  [MATERIAL_BATCH_STATUSES.NEW]: 'New',
  [MATERIAL_BATCH_STATUSES.IN_USE]: 'In Use',
  [MATERIAL_BATCH_STATUSES.DONE]: 'Done',
};

export const WORK_INSTRUCTION_FILE_TYPES = [
  WORK_INSTRUCTION_TYPES.PICTURE,
  WORK_INSTRUCTION_TYPES.DOCUMENT,
];

export const LOCALSTORAGE_KEYS = {
  GOD_MODE: 'godMode',
}

export const REFERENCE_TABLE_NAMES = {
  WORK_INSTRUCTION_REPORT: 'work_instruction_report',
}

export const TIME_ENTRY_RELATED_TABLE_NAME = {
  RUN: 'run',
  PREP_TASK_RECORD: 'prep_task_record',
}

export const TIME_ENTRY_CATEGORIES = {
  ATTENDING: 'attending',
  MACHINE_RUNNING: 'machine-running',
  // Preparation workflow feature (is not used in QR):
  IN_PROGRESS: 'in-progress',
  ON_HOLD: 'on-hold',
}

export const PRINT_TYPES = {
  PRODUCT: 'product',
  SPECIMEN: 'specimen',
  // For future use
  WASTE: 'waste',
  TOOLING: 'tooling',
}

export const WORK_INSTRUCTION_UNITS = {
  MM: 'mm',
  CM: 'cm',
  INCH: 'inch',
  GRAM: 'gram',
  KG: 'kg',
  LB: 'lb',
};

export const WORK_INSTRUCTION_REPORT_UNITS_VERBOSE = {
  [WORK_INSTRUCTION_UNITS.MM]: 'mm',
  [WORK_INSTRUCTION_UNITS.CM]: 'cm',
  [WORK_INSTRUCTION_UNITS.INCH]: 'in',
  [WORK_INSTRUCTION_UNITS.GRAM]: 'g',
  [WORK_INSTRUCTION_UNITS.KG]: 'kg',
  [WORK_INSTRUCTION_UNITS.LB]: 'lb',
};

export const MODEL_UNITS_VERBOSE = {
  [WORK_INSTRUCTION_UNITS.MM]: 'Millimeters',
  [WORK_INSTRUCTION_UNITS.CM]: 'Centimeters',
  [WORK_INSTRUCTION_UNITS.INCH]: 'Inches',
};

export const ALLOWED_DOCUMENT_UPLOAD_FORMATS = [
  'doc',
  'docx',
  'pdf',
  'stl',
  'mp3',
  'dxf',
  'csv',
  'xls',
  'xlsx',
  'jpeg',
  'png',
  'obj',
  'stp',
  'step',
  '3mf',
  'dae',
  'ply',
  'igs',
  'brep',
  'catpart',
  'cmb',
];

// TODO This variable is created to track points where do we need to fetch
//  all non-paginated objects.
//  Must be removed after implementing algorithm for disabling pagination
export const PAGINATION_IGNORE_DEFAULT_LIMIT = 5000;

export const CONTACTLESS_LOGON_TIMEOUT = 3600 * 1000;
export const LOGON_WARNING_TIMEOUT = 30 * 1000;
