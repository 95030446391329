import React from 'react';
import PropTypes from 'prop-types';
import Header from '../components/header.jsx';
import Alert from '../components/alert.jsx';
import config from '../utils/config';
import Sentry from '../utils/sentry';

const ErrorPage = ({ statusCode, userName, userEmail, error, eventId }) => {
  const showReportButton = config.sentryDsn && !statusCode;
  const defaultMessage = 'Please hit the back button and try again. If you continue to have issues, please contact us.';

  return (
    <>
      <Header title="Authentise" />
      <main role="main">
        <Alert name="error-page" variant="danger" className="text-left">
          <strong>Something went wrong.</strong>{' '}
          {error && (
            <div className="mt-2 mb-0">{error.message || defaultMessage}</div>
          )}
          {statusCode && (
            <div className="mt-2 mb-0">Status code: {statusCode}</div>
          )}
          {showReportButton && (
            <>
              <p className="mt-2">We’ve been automatically notified, but if you’d like to provide us with more details, please use the button below to submit a report.</p>
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => Sentry.showReportDialog({
                  title: 'Something went wrong.',
                  subtitle: 'Our team has been notified. If you’d like to help, tell us what happened below.',
                  subtitle2: '',
                  labelSubmit: 'Submit Report',
                  eventId,
                  user: {
                    name: userName,
                    email: userEmail,
                  },
                })}
              >
                Submit Report
              </button>
            </>
          )}
        </Alert>
      </main>
    </>
  );
};

export async function getServerSideProps(contextData) {
  const { res, err } = contextData;
  const statusCode = res ? res.statusCode : (err ? err.statusCode : null);

  // https://github.com/vercel/next.js/blob/canary/examples/with-sentry/pages/_error.js
  // Running on the server, the response object (`res`) is available.
  //
  // Next.js will pass an err on the server if a page's data fetching methods
  // threw or returned a Promise that rejected
  //
  // Running on the client (browser), Next.js will provide an err if:
  //
  //  - a page's `getInitialProps` threw or returned a Promise that rejected
  //  - an exception was thrown somewhere in the React lifecycle (render,
  //    componentDidMount, etc) that was caught by Next.js's React Error
  //    Boundary. Read more about what types of exceptions are caught by Error
  //    Boundaries: https://reactjs.org/docs/error-boundaries.html


  // In case this is running in a serverless function, await this in order to give Sentry
  // time to send the error before the lambda exits
  if (Sentry.captureUnderscoreErrorException && typeof Sentry.captureUnderscoreErrorException === 'function') {
    await Sentry.captureUnderscoreErrorException(contextData);
  }

  if (err) {
    Sentry.captureException(err)

    // Flushing before returning is necessary if deploying to Vercel, see
    // https://vercel.com/docs/platform/limits#streaming-responses
    await Sentry.flush(2000)
  }

  return { props:  { statusCode } };
}

ErrorPage.propTypes = {
  statusCode: PropTypes.number,
  userName: PropTypes.string,
  userEmail: PropTypes.string,
  error: PropTypes.instanceOf(Error),
  eventId: PropTypes.string,
};

ErrorPage.defaultProps = {
  statusCode: null,
  userName: '',
  userEmail: '',
  error: null,
  eventId: '',
};

export default ErrorPage;
