import ky from 'ky-universal';
import config from './config';
import Sentry from './sentry';

export function getAllByFilterWithApi(api) {
  return async (items, endpoint, options = {}, filter = 'uri') => {
    console.log('Inside getAllByFilterWithApi, checking for items', items);
    console.log('Inside getAllByFilterWithApi, checking for endpoint', endpoint);
    console.log('Inside getAllByFilterWithApi, checking for options', options);
    console.log('Inside getAllByFilterWithApi, checking for filter', filter);
    const chunkedItems = chunkForQueryFilter(items);
    const filterKey = `filter[${filter}]`;

    const requests = chunkedItems.map((chunk) => api.get(endpoint, {
      ...options,
      searchParams: {
        ...options.searchParams,
        [filterKey]: chunk.join(','),
      },
    }).json());

    console.log('Inside getAllByFilterWithApi, checking for requests', requests);

    const responses = await Promise.all(requests);

    console.log('Inside getAllByFilterWithApi, checking for responses', responses);

    const data = responses.map(({ resources }) => resources);

    console.log('Inside getAllByFilterWithApi, checking for data', data);

    return [].concat(...data);
  };
}

export const chunkForQueryFilter = (items = [], itemsPerChunk = 15) => {
  if (items.length === 0) {
    return [];
  }

  const result = [];

  while (items.length > 0) {
    result.push(items.splice(0, itemsPerChunk));
  }

  console.log('Inside chunkForQueryFilter, checking for result', result);

  return result;
};

export const getCookieFromContext = ({ req } = {}) => {
  if (req && req.headers && req.headers.cookie) {
    console.log("req && req.headers && req.headers.cookie condition followed inside getCookieFromContext", req.headers.cookie);
    return req.headers.cookie;
  }

  return false;
};


console.log("Checking CONFIG API HOST: ", config.apiHost);

export const api = ky.extend({
  credentials: 'include',
  prefixUrl: config.apiHost,
  timeout: 60 * 1000,
  hooks: {
      beforeRequest: [
        (request) => {
          /*
           * We should avoid requests without slash at the end of pathname.
           * nginx returns 308 status code and application makes additional request for
           * api service with slash
           */
          let requestURL = new URL(request.url);
          if(!requestURL.pathname.endsWith('/')) {
            console.log("Condition followed inside api, !requestURL.pathname.endsWith('/')");
            console.log('Inside api, checking for requestURL.pathname', requestURL.pathname);
            Sentry.captureEvent({
              message: 'The request is executed without the trailing slash in pathname',
              level: 'warning',
              extra: { uri: request.url },
            });
          }
        },
      ],
      afterResponse: [
        async (request, options, response) => {
          console.log('Inside api, checking for request', request);
          console.log('Inside api, checking for options', options);
          console.log('Inside api, checking for response', response);
          if (!response.ok && options.onErrorToastCallback) {
            console.log("Condition followed inside api, !response.ok && options.onErrorToastCallback");
            const message = await response.json();
            if(message.errors) {
              options.onErrorToastCallback(message.errors[0].title, { appearance: "error" });
            }
          }
        },
      ],
    },
});

export const getAllByFilter = getAllByFilterWithApi(api);

export const apiWithContext = (ctx) => {
  console.log('Inside apiWithContext, checking for ctx', ctx);
  const cookie = getCookieFromContext(ctx);

  console.log('Inside apiWithContext, checking for cookie', cookie);

  const contextApi = api.extend({
    credentials: 'include',
    prefixUrl: config.apiHost,
    hooks: {
      beforeRequest: [
        (request) => {
          console.log('Inside apiWithContext, checking for request', request);
          if (cookie) {
            request.headers.append('Cookie', cookie);
          }
        },
      ],
      afterResponse: [
        (request, options, response) => {
          console.log('Inside apiWithContext, checking for request', request);
          console.log('Inside apiWithContext, checking for options', options);
          console.log('Inside apiWithContext, checking for response', response);
          if (ctx && response.headers.has('Set-Cookie')) {
            ctx.res.setHeader('Set-Cookie', response.headers.get('Set-Cookie'));
          }
        },
      ],
    },
  });

  contextApi.getAllByFilter = getAllByFilterWithApi(contextApi);

  console.log("Inside apiWithContext, return contextApi ibj", contextApi);

  return contextApi;
};

// Neede because fetch doesn’t support upload progress.
// WILL NOT WORK SERVER-SIDE
export const upload = (url, options) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.withCredentials = true;
    xhr.open('PUT', url);
    xhr.setRequestHeader('content-type', options.contentType);

    xhr.addEventListener('abort', reject);
    xhr.addEventListener('error', reject);

    xhr.upload.addEventListener('progress', ({ loaded, total }) => {
      options.onUploadProgress(loaded / total);
    });

    xhr.addEventListener('load', () => {
      if (xhr.status >= 200 && xhr.status < 400) {
        resolve(xhr.response);
        return;
      }

      let errors = [];
      try {
        errors = JSON.parse(xhr.response).errors;
      } catch (_) {}

      if(errors.length) {
        errors.map((error) => {
          options.onErrorToastCallback(error.title, { appearance: "error" });
        });
      }
      reject(xhr.status);
    });

    xhr.send(options.body);
  });
};
