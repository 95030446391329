import React from 'react';
import PropTypes from 'prop-types';
import App from 'next/app';
import Head from 'next/head';
import Router from 'next/router';
import NProgress from 'nprogress';
import { CSSTransition } from 'react-transition-group';
import { config as fontAwesomeConfig } from '@fortawesome/fontawesome-svg-core';
import { ToastProvider } from 'react-toast-notifications';
import '@fortawesome/fontawesome-svg-core/styles.css';

import '../styles/main.css';
import '../styles/materials-management.css';
import '../styles/piece-overview.css'
import '../styles/traveler.css';
import '../styles/comments.css';
import InstallPrompt from '../components/install-prompt.jsx';
import SentryErrorBoundary from '../components/sentry-error-boundary.jsx';
import { getSession } from '../utils/auth';
import config from '../utils/config';
import redirect from '../utils/redirect';
import Sentry, { init as initSentry } from '../utils/sentry';
import userPropType from '../utils/user-prop-type';
import FeatureContext from '../components/feature-context';
import { apiWithContext } from '../utils/api';

fontAwesomeConfig.autoAddCss = false;

Router.onRouteChangeStart = () => NProgress.start();
Router.onRouteChangeComplete = () => NProgress.done();
Router.onRouteChangeError = () => NProgress.done();


initSentry({
  dsn: config.sentryDsn,
  environment: config.environment,
  release: config.commit,
  sampleRate: config.sentrySampleRate,
});

class AuthentiseQRApp extends App {
  static async getInitialProps({ Component, ctx }) {
    console.log("Inside AuthentiseQRApp, in getInitialProps, checking for Component", Component);
    console.log("Inside AuthentiseQRApp, in getInitialProps, checking for ctx", ctx);
    if (ctx && ctx.res) {
      console.log("Inside AuthentiseQRApp, in getInitialProps, checking for ctx.res", ctx);
      const isLocalDev = config.environment === 'local';

      const csp = [
        `default-src 'self' ${config.apiHost}${isLocalDev ? ' ws: wss:' : ''} blob: https://o134287.ingest.sentry.io`,
        'script-src \'self\' \'unsafe-eval\' \'unsafe-inline\' blob:',
        'style-src \'self\' \'unsafe-inline\' https://stackpath.bootstrapcdn.com',
        `img-src 'self' ${config.apiHost} data:`,
      ];

      ctx.res.setHeader('Content-Security-Policy', csp.join(';'));
      ctx.res.setHeader('Referrer-Policy', 'strict-origin-when-cross-origin');
      ctx.res.setHeader('X-Content-Type-Options', 'nosniff');
      ctx.res.setHeader('X-Frame-Options', 'deny');
      ctx.res.setHeader('X-XSS-Protection', '1; mode=block');

      if (!isLocalDev) {
        ctx.res.setHeader('Strict-Transport-Security', 'max-age=31536000; includeSubDomains');
      }
    }

    let user = null;

    console.log("User first initialized", user);

    try {
      user = await getSession(ctx);
      console.log("User initialized after getSession()", user);
    } catch (error) {
      Sentry.captureException(error);
    }

    if (user) {
      console.log("We have user initialized after getSession()", user);
      const api = apiWithContext(ctx);
      console.log("API for Features", api);
      const { resources: features } = await api.get('feature/').json();
      ctx.features = features;
    }

    console.log("NO USER FOUND, SO CONDITION if (user) did not follow", user);

    // By default, all pages require a user to be logged in.
    // A static property, "allowLoggedOut" can be added to the component to
    // prevent a redirect to "/log-in".
    // Additionally, adding "requireLoggedOut" will redirect to "/" if the user
    // is logged in. (Basically just for the login page)
    const requireSession = !Component.allowLoggedOut && !Component.requireLoggedOut;

    console.log("requireSession check", requireSession);
    console.log("Component.allowLoggedOut check", Component.allowLoggedOut);
    console.log("Component.requireLoggedOut check", Component.requireLoggedOut);

    if (requireSession && !user) {
      console.log("requireSession && !user condition followed, REDIRECTING TO LOGIN", user, requireSession);
      redirect('/log-in', ctx);
      return {};
    }

    if (Component.requireLoggedOut && user) {
      console.log("Component.requireLoggedOut && user condition followed, REDIRECTING TO HOME", user, Component.requireLoggedOut);
      redirect('/', ctx);
      return {};
    }

    const pageProps = await (Component.getInitialProps ? Component.getInitialProps(ctx) : {});

    console.log("Inside AuthentiseQRApp, in getInitialProps, checking for pageProps", pageProps);

    return {
      pageProps: {
        ...pageProps,
        user,
      },
      features: ctx.features || [],
    };
  }

  state = {
    installPrompt: false,
  }

  componentDidMount() {
    const { user } = this.props.pageProps;
    console.log("Inside AuthentiseQRApp, in componentDidMount, checking for user", user);
    const { pathname } = this.props.router;
    console.log("Inside AuthentiseQRApp, in componentDidMount, checking for pathname", pathname);

    if (user) {
      Sentry.configureScope((scope) => {
        scope.setUser({
          id: user.uri,
          email: user.username,
          name: user.name,
        });
      });
    }

    console.log("NO USER FOUND in componentDidMount, so condition if (user) did not follow", user);

    window.addEventListener('beforeinstallprompt', (event) => {
      event.preventDefault();

      let hidePrompt = false;
      const travelerPage = pathname.startsWith('/traveler');

      try {
        const storageValue = window.localStorage.getItem('installPrompt');
        hidePrompt = storageValue === 'hidden';
      } catch (_) {
        // ignore
      }

      if (hidePrompt || travelerPage) {
        return;
      }

      this.setState({
        installPrompt: event,
      });
    });
  }

  render() {
    const { Component, pageProps, features } = this.props;
    const { installPrompt } = this.state;

    console.log("Checking for installPrompt", installPrompt);

    let userName = '';
    let userEmail = '';

    if (pageProps.user) {
      userName = pageProps.user.name;
      userEmail = pageProps.user.username;
    }

    const hideInstallPrompt = () => {
      this.setState({
        installPrompt: false,
      });

      try {
        window.localStorage.setItem('installPrompt', 'hidden');
      } catch (_) {
        // ignore
      }
    };

    const promptToInstall = () => {
      installPrompt.prompt();
      installPrompt.userChoice.then(() => {
        hideInstallPrompt();
      });
    };

    return (
      <>
        <Head>
          <meta charSet="utf-8" />
          <meta name="viewport" content="initial-scale=1.0,width=device-width" />
          <title>Authentise QR</title>
          <link rel="manifest" href="/static/manifest.json" />
          <link rel="apple-touch-icon-precomposed" href="/static/icon-192.png" />
          <link rel="shortcut icon" href="/static/favicon.ico" />
          <link rel="icon" sizes="16x16 32x32" href="/static/favicon.ico" />
          <meta name="theme-color" content="#e19d40" />
          <link rel="author" href="humans.txt" />
          <link
            rel="stylesheet"
            href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.0/css/bootstrap.min.css"
            integrity="sha384-PDle/QlgIONtM1aqA2Qemk5gPOE7wFq8+Em+G/hmo5Iq0CCmYZLv3fVRDJ4MMwEA"
            crossOrigin="anonymous"
          />
          <link rel="preconnect" href={config.apiHost} />
        </Head>
        <SentryErrorBoundary userName={userName} userEmail={userEmail}>
          <ToastProvider
            autoDismiss
            autoDismissTimeout={4000}
          >
            <FeatureContext.Provider value={{ features }}>
              <Component {...pageProps} />
            </FeatureContext.Provider>
          </ToastProvider>
          <CSSTransition in={Boolean(installPrompt)} timeout={200} classNames="slide">
            <InstallPrompt onConfirm={promptToInstall} onDismiss={hideInstallPrompt} />
          </CSSTransition>
        </SentryErrorBoundary>
      </>
    );
  }
}

AuthentiseQRApp.propTypes = {
  Component: PropTypes.func.isRequired,
  pageProps: PropTypes.object,
  user: userPropType,
  features: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default AuthentiseQRApp;
