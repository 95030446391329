import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import Link from 'next/link';
import Router from 'next/router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faSun, faSignOut, faQrcode } from '@fortawesome/pro-regular-svg-icons';
import { faChevronCircleLeft } from '@fortawesome/pro-solid-svg-icons';
import { useCookies } from 'react-cookie';
import SessionCountdown from './session/session-countdown';

const getAvatarName = (name) => {
  if (name?.includes(' ')) {
    const [first, second] = name.split(' ');
    return `${first.slice(0, 1)}${second.slice(0, 1)}`;
  }

  return name?.slice(0, 2);
};

const BackButton = React.forwardRef(({ onClick }, ref) => (
  <button ref={ref} type="button" className="btn text-reset" aria-label="Back" onClick={onClick}>
    <FontAwesomeIcon icon={faChevronCircleLeft} />
  </button>
));

BackButton.displayName = 'BackButton';

BackButton.propTypes = {
  onClick: PropTypes.func,
};

BackButton.defaultProps = {
  onClick: null,
};

const Header = ({ title, back, menu, user, scan }) => {
  const pageTitle = title.startsWith('Authentise') ? title : `Authentise – ${title}`;

  const backLink = typeof back !== 'function';
  const hideBack = back === '';
  const [cookies] = useCookies(['contactlessLoginEnabled']);

  return (
    <header role="banner">
      <Head>
        <title>{pageTitle}</title>
      </Head>
      <nav role="navigation" className="navbar navbar-dark bg-primary navbar-expand-sm">
        <div className="navbar-square-item d-flex justify-content-center align-items-center">
          {!hideBack && backLink && (
            <Link href={back}>
              <BackButton />
            </Link>
          )}
          {!hideBack && !backLink && (
            <BackButton onClick={back} />
          )}
        </div>
        <div className="text-center flex-grow-1">
          <div className="navbar-text" data-testid="header-title">
            {title}
          </div>
        </div>
        <div>
          {!menu && user && cookies.contactlessLoginEnabled && (
            <SessionCountdown />
          )}

          {!menu && user && (
            <Link href="/about">
              <button type="button" className="btn text-reset" aria-label="About Page">
                <FontAwesomeIcon icon={faSun} />
              </button>
            </Link>
          )}
        </div>
        <div className="navbar-square-item d-flex justify-content-center align-items-center">
          {menu && (
            <Link href={menu}>
              <button type="button" className="btn text-reset" aria-label="Recent Activity">
                <FontAwesomeIcon icon={faBars} />
              </button>
            </Link>
          )}
          {!menu && user && (
            <div className="avatar w-100 h-100 d-flex justify-content-center align-items-center">
              <span aria-label={`${user.name}`}>
                {getAvatarName(user.name)}
              </span>
            </div>
          )}
        </div>
        {!menu && user && (
          <Link href="/log-out">
            <button type="button" className="btn text-reset logout-btn" aria-label="Log out">
              <FontAwesomeIcon icon={faSignOut} color='#000' />
            </button>
          </Link>
        )}
        {!scan && !menu && user && (
          <Link href="/scan">
            <button
              type="button"
              className="btn text-reset scan-btn"
              aria-label="New Scan"
            >
              <FontAwesomeIcon icon={faQrcode} color='#000' />
            </button>
          </Link>
        )}
      </nav>
    </header>
  );
};

Header.propTypes = {
  title: PropTypes.string,
  back: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
  menu: PropTypes.string,
  scan: PropTypes.bool,
  user: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
};

Header.defaultProps = {
  title: 'Authentise',
  back: () => Router.back(),
  menu: '',
  scan: false,
  user: null,
};

export default Header;
