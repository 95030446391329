import getConfig from 'next/config';

const { publicRuntimeConfig = {} } = getConfig() || {};

// This should contain all the values in the next.config.js file 'env'.
// Values that set in just env should only have process.env.* and should not use
// the public runtime config.
export default {
  commit: process.env.commit,
  version: process.env.version,
  buildId: process.env.buildId,
  sentryDsn: publicRuntimeConfig.sentryDsn || process.env.sentryDsn,
  sentrySampleRate: publicRuntimeConfig.sentrySampleRate || process.env.sentrySampleRate,
  environment: publicRuntimeConfig.environment || process.env.environment,
  apiHost: publicRuntimeConfig.apiHost || process.env.apiHost,
  rapidfabHost: publicRuntimeConfig.rapidfabHost || process.env.rapidfabHost,
  test: process.env.NODE_ENV === 'test',
};
