import Router from 'next/router';

const redirect = (to, context) => {
  if (context.req) {
    context.res.writeHead(302, {
      Location: to,
    });
    context.res.end();
    return;
  }

  Router.push(to);
};

export default redirect;
