import { api, apiWithContext } from './api';

export const checkSSO = async (email) => {
  return api.get(`saml/${email}/`, {
    searchParams: {
      // eslint-disable-next-line camelcase
      return_to: `${window.location.origin}/scan`,
    },
  }).json();
};

export const createSession = async (username, password) => {
  const createSessionResponse = api.post('sessions/', {
    json: {
      username: `${username}`.trim(),
      password,
    },
  }).json();

  console.log('Inside createSession, checking for createSessionResponse', createSessionResponse);

  return createSessionResponse;
};

export const destroySession = async (ctx = {}) => {
  const destroySessionResponse = apiWithContext(ctx).delete('sessions/').json();
  console.log('Inside destroySession, checking for destroySessionResponse', destroySessionResponse);
  return destroySessionResponse;
};

export const getSession = async (ctx = {}) => {
  const getSessionResponse = apiWithContext(ctx).get('sessions/').json();
  console.log('Inside getSession, checking for getSessionResponse', getSessionResponse);
  return getSessionResponse;
};
