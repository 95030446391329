import React, { useEffect, useState, useRef } from 'react';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { destroySession, getSession } from '../../utils/auth';
import { CONTACTLESS_LOGON_TIMEOUT, LOGON_WARNING_TIMEOUT } from '../../utils/constants';
import Modal from '../modal';
import { parseCookie } from "../../utils/cookie";

const SessionCountdown = () => {
  const [autoLogoutAfter, setAutoLogoutAfter] = useState(null);
  const [timeLeft, setTimeLeft] = useState(Math.floor(CONTACTLESS_LOGON_TIMEOUT / 1000));
  const interval = useRef(null);

  const handleVisibilityChange = () => {
    if (document.hidden) {
      return;
    }
    handleAutoLogout();
  };


  /**
   * Handles auto logout functionality for the users logged in with QR code logon
   * @returns {void}
   */
  const handleAutoLogout = () => {
    window.clearInterval(interval.current);
    const { contactlessLoginEnabled } = parseCookie(document.cookie);
    if (!contactlessLoginEnabled) {
      return;
    }
      interval.current = window.setInterval(() => {
        let { autologoutAfter: cookieAutoLogoutAfter } = parseCookie(document.cookie);
        if (cookieAutoLogoutAfter) {
          // persist autologoutAfter in order to save it across page refreshes. reopenigs and PWA suspensions
          localStorage.setItem('autologoutAfter', cookieAutoLogoutAfter);
        } 
        cookieAutoLogoutAfter = cookieAutoLogoutAfter || localStorage.getItem('autologoutAfter');
        if (cookieAutoLogoutAfter && cookieAutoLogoutAfter !== autoLogoutAfter) {
          setAutoLogoutAfter(cookieAutoLogoutAfter)
          const timeDelta = new Date(cookieAutoLogoutAfter).getTime() - new Date().getTime()
          const timeLeft = Math.max(Math.floor(timeDelta / 1000), 0)
          setTimeLeft(timeLeft)
          if (timeLeft <= 0) {
            handleLeave();
          }
        }
    }, 1000);
  }

  useEffect(() => {
    handleAutoLogout();
    return () => window.clearInterval(interval.current);
  }, []);

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange, false);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      if (interval.current) {
        window.clearInterval(interval.current);
      }
    }
  }, []);

  // Effect to hadle standalone WebApp reopenning
  useEffect(() => {
    window.addEventListener("pageshow", handleAutoLogout);
    return () => {
      window.removeEventListener("pageshow", handleAutoLogout);
    };
  }, []);

  const handleKeepSessionAlive = () => {
    getSession()
  }

  const handleLeave = () => {
    if (interval.current) {
      window.clearInterval(interval.current);
    }
    
    destroySession()
    .catch((error) => console.error(error))
    .finally(() => {
      localStorage.removeItem('autologoutAfter');
      location.reload();
    });
    
  }

  return (
    <>
      {timeLeft <= LOGON_WARNING_TIMEOUT / 1000 && (
        <OverlayTrigger
          placement="bottom"
          overlay={(
            <Tooltip id="workstation-is-overloaded">
              Current session expiration time
            </Tooltip>)}
        >
          <button type="button" className="btn text-reset" aria-label="Session exiration">
            {timeLeft}
          </button>
        </OverlayTrigger>
      )}
      <Modal
        isOpen={timeLeft <= LOGON_WARNING_TIMEOUT / 1000}
        title="Your session is about to expire"
        dismissText="Logout"
        confirmText="Keep Session Alive"
        onConfirm={handleKeepSessionAlive}
        onDismiss={handleLeave}
      >
        <div className="modal-body">
          <p>
            Your session will expire in {timeLeft} seconds.
          </p>
        </div>
      </Modal>
    </>
  );
}

export default SessionCountdown;
