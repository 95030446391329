import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Alert = ({ name, dismissible, children, className, variant, ...props }) => {
  const localStorageKey = `alert-${name}-dismissed`;
  const [dismissed, setDismissed] = useState(false);

  const alertClassName = classNames('alert', `alert-${variant}`, className, {
    'alert-dismissible': dismissible,
    fade: dismissible,
    show: dismissible && !dismissed,
  });

  const onDismiss = () => {
    localStorage.setItem(localStorageKey, true);
    setDismissed(true);
  };

  const onTransitionEnd = (...args) => {
    console.log(args);
  };

  useEffect(() => {
    const localStorageValue = localStorage && localStorage.getItem(localStorageKey);
    setDismissed(localStorageValue);
  }, [localStorageKey]);

  return (
    <p className={alertClassName} role="alert" {...props} data-testid="alert" onTransitionEnd={onTransitionEnd}>
      {children}
      {dismissible && (
        <button
          type="button"
          className="close"
          aria-label="Close"
          data-testid="close-button"
          onClick={onDismiss}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      )}
    </p>
  );
};

Alert.propTypes = {
  name: PropTypes.string.isRequired,
  dismissible: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'danger',
    'warning',
    'info',
    'dark',
    'light',
  ]),
};

Alert.defaultProps = {
  dismissible: false,
  className: '',
  variant: 'info',
};

export default Alert;
